// extracted by mini-css-extract-plugin
export var continueWatchingSection = "ContinueWatching-module--continueWatchingSection--1uMj3";
export var sectionTitle = "ContinueWatching-module--sectionTitle--24Mig";
export var wrap = "ContinueWatching-module--wrap--2uMlo";
export var viewAllCta = "ContinueWatching-module--viewAllCta--1rgWD";
export var recordingWrapper = "ContinueWatching-module--recordingWrapper--1_Z3E";
export var leftContent = "ContinueWatching-module--leftContent--2xhvu";
export var recordingDetails = "ContinueWatching-module--recordingDetails--DP2Fb";
export var title = "ContinueWatching-module--title--1I3G1";
export var info = "ContinueWatching-module--info--1zjcD";
export var playIconWrapper = "ContinueWatching-module--playIconWrapper--dVvPY";