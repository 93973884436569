import { navigate } from 'gatsby';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import LearnerContext from '../../context/Learner/LearnerContext';
import * as styles from '../../styles/learner/EnrolledCourses.module.scss';
import EnrolledCourseCard from './EnrolledCourseCard';

/**
 *
 *
 * @return {*}
 */
const EnrolledCourses: React.FC = () => {
  const [scrollBarWith, setScrollBarWidth] = useState<number>(0);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const { courseRegistrations } = useContext(LearnerContext);
  const carouselRef = useRef<CarouselRef | null>(null);
  const scrollBarParentRef = useRef<HTMLDivElement | null>(null);
  const mobileView = window?.innerWidth <= 426;

  const navigateToBrowsePage = () => {
    navigate('/learner/browse/');
  };

  const handleClickPrev = () => {
    carouselRef?.current?.prev();
  };

  const handleClickNext = () => {
    carouselRef?.current?.next();
  };

  const ellipseDisplayHandler = () => {
    if (courseRegistrations.length > 0) {
      if (courseRegistrations.length <= 2) {
        return <></>;
      }
      if (courseRegistrations.length > 2 && courseRegistrations.length <= 4) {
        return Array.from({ length: 2 }, (_, index) => (
          <div key={index} className={styles.ellipse} />
        ));
      }

      return Array.from({ length: 3 }, (_, index) => (
        <div key={index} className={styles.ellipse} />
      ));
    }

    return <></>;
  };

  const handleCarouselSlideChange = (index: number) => {
    setCarouselIndex(index);
  };

  const carouselSlidesToShowHandler = () => {
    if (courseRegistrations?.length < 2) {
      return 1;
    }

    return 2;
  };

  const courseCardDisplayHandler = () => (
    <>
      <Carousel
        ref={carouselRef}
        dots={false}
        infinite
        slidesToScroll={2}
        initialSlide={0}
        slidesToShow={carouselSlidesToShowHandler()}
        afterChange={handleCarouselSlideChange}
      >
        {
          courseRegistrations.map((registration) => (
            <EnrolledCourseCard key={registration.id} registration={registration} />
          ))
        }
      </Carousel>
      {
        courseRegistrations.length > 2
        && (
          <div className={styles.dummyScrollBarWrapper} ref={scrollBarParentRef}>
            <div className={styles.dummyScrollBar} style={scrollBarStyleHandler()} />
          </div>
        )
      }
    </>
  );

  const scrollBarStyleHandler = () => {
    let marginLeft = 0;
    const barDivisionMetric = 2;

    if (carouselIndex) {
      marginLeft = (carouselIndex / barDivisionMetric) * scrollBarWith;
    }
    return {
      width: scrollBarWith,
      marginLeft,
      transition: 'margin-left 0.3s ease',
    };
  };

  useEffect(() => {
    if (courseRegistrations) {
      const parentWidth = scrollBarParentRef?.current?.clientWidth || 0;
      let totalCourseRegistrations = courseRegistrations.length;

      if (totalCourseRegistrations % 2 !== 0) {
        totalCourseRegistrations += 1;
      }
      const scrollBar = Math.floor(parentWidth / (totalCourseRegistrations / 2));

      setScrollBarWidth(scrollBar);
    }
  }, [courseRegistrations]);

  return (
    <div className={`${styles.enrolledCoursesSection} ${courseRegistrations?.length > 0 ? '' : styles.hide}`}>
      <div className={styles.headerWrapper}>
        <h4 className={styles.heading}>
          My Courses
        </h4>
        {
          !mobileView && courseRegistrations?.length > 2
          && (
            <div className={styles.carouselControls}>
              <div className={styles.prevCtaWrapper}>
                <button type="button" onClick={handleClickPrev} className={styles.prevCta}>{'<'}</button>
              </div>
              <div className={styles.ellipseWrapper}>
                {
                  ellipseDisplayHandler()
                }
              </div>
              <div className={styles.nextCtaWrapper}>
                <button type="button" onClick={handleClickNext} className={styles.nextCta}>Next</button>
              </div>
            </div>
          )
        }
      </div>
      <div className={styles.enrolledCourseList}>
        {
        !mobileView && (courseRegistrations?.length > 0
          ? (
            courseCardDisplayHandler()
          )
          : (
            <button type="button" onClick={navigateToBrowsePage} className={styles.addCourse}>
              <div>
                <div>+</div>
                <p>Add a course</p>
              </div>
            </button>
          ))
        }
        {
        mobileView && (courseRegistrations?.length > 0
          ? (
            <div className={styles.enrolledCoursesWrapper}>
              {
              courseRegistrations.map((registration) => (
                <EnrolledCourseCard key={registration.id} registration={registration} />
              ))
            }
            </div>
          )
          : (
            <button type="button" onClick={navigateToBrowsePage} className={styles.addCourse}>
              <div>
                <div>+</div>
                <p>Add a course</p>
              </div>
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default EnrolledCourses;
