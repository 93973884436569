// extracted by mini-css-extract-plugin
export var enrolledCourseItem = "EnrolledCourseCard-module--enrolledCourseItem--3oMzg";
export var courseImage = "EnrolledCourseCard-module--courseImage--98dcF";
export var courseName = "EnrolledCourseCard-module--courseName--32uIH";
export var courseNameTooltip = "EnrolledCourseCard-module--courseNameTooltip--2GBDb";
export var progressBar = "EnrolledCourseCard-module--progressBar--2swLV";
export var task = "EnrolledCourseCard-module--task--vFffp";
export var courseInfoWrapper = "EnrolledCourseCard-module--courseInfoWrapper--16pND";
export var taskCompletionInfo = "EnrolledCourseCard-module--taskCompletionInfo--1yliE";
export var taskTitle = "EnrolledCourseCard-module--taskTitle--3T1pJ";
export var tasksCompleted = "EnrolledCourseCard-module--tasksCompleted--tjfMZ";
export var scoreContainer = "EnrolledCourseCard-module--scoreContainer--AsW6j";
export var scoreWrapper = "EnrolledCourseCard-module--scoreWrapper--2EW5d";
export var scoreInfo = "EnrolledCourseCard-module--scoreInfo--1CiRw";
export var scorePercentage = "EnrolledCourseCard-module--scorePercentage--1SBZD";
export var scoreNote = "EnrolledCourseCard-module--scoreNote--2rJJ-";