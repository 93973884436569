import React, { useState, useEffect, useContext } from 'react';
import { Collapse } from 'antd';
import { Link } from 'gatsby';
import * as styles from '../../styles/learner/LearnerOnboarding.module.scss';
import LearnerContext from '../../context/Learner/LearnerContext';
import { fetchCourseRegistrationActvitiesByRegistrationId } from
  '../DataStore/CourseRegistrationActivityService';
import { CourseRegistrationActivity, CourseRegistrationActivityStatus, TaskType } from '../../models';
import useSkillsReportId from '../../hooks/useSkillsReportId';
import { fetchSkillsReport, fetchSkillsReportResult } from '../../services/ReportAnalysisService';

const { Panel } = Collapse;

interface OnboardingStatusType {
  completedSkillsGapAnalysis: boolean,
  courseEnrolled: boolean,
  watchedLesson: boolean,
  uploadedProfilePic: boolean,
}

export interface LearnerOnboardingProgressType {
  status: OnboardingStatusType,
  loading: boolean,
}

const defaultState = {
  status: {
    completedSkillsGapAnalysis: false,
    courseEnrolled: false,
    watchedLesson: false,
    uploadedProfilePic: false,
  },
  loading: true,
};

enum OnboardingStatus {
  COMPLETED_SKILLS_ANALYSIS = 'completedSkillsGapAnalysis',
  COURSE_ENROLLED = 'courseEnrolled',
  UPLOADED_PROFILE_PIC = 'uploadedProfilePic',
  WATCHED_LESSON = 'watchedLesson',
}

const LearnerOnboarding:React.FC = () => {
  const { courseRegistrations, profileInfo } = useContext(LearnerContext);
  const [onboardingTasksProgress, setOnboardingTasksProgress] = useState<
  LearnerOnboardingProgressType>(defaultState);

  const { reportId } = useSkillsReportId();

  const isLessonWatched = async ():Promise<boolean> => {
    if (!courseRegistrations?.length) return false;
    const promises = courseRegistrations.map((
      registration,
    ) => fetchCourseRegistrationActvitiesByRegistrationId(
      registration?.id,
    ));
    const allRegistrationsActivities = await Promise.all(promises);
    const registrationsWithLessonWatched = allRegistrationsActivities.filter((
      registrationActivities,
    ) => registrationActivities.some((activity:CourseRegistrationActivity) => (
      (activity?.taskType === TaskType.VIDEO)
        && (activity.status === CourseRegistrationActivityStatus.COMPLETED)
    )));
    return registrationsWithLessonWatched?.length > 0;
  };

  const checkSkillsReportResult = async () => {
    const report = await fetchSkillsReport();
    if (report) {
      const reportID = report?.id;
      const enterpriseId = profileInfo?.enterpriseID || '';
      const result = await fetchSkillsReportResult(reportID, enterpriseId);
      if (result && !result?.archived) return true;
    }

    return false;
  };

  const checkOnboardingProgress = async () => {
    const courseEnrolled = courseRegistrations?.length > 0;
    const uploadedProfilePic = !!profileInfo?.profilePicture;
    let watchedLesson = false;
    if (courseEnrolled) {
      watchedLesson = await isLessonWatched();
    }
    const completedSkillsGapAnalysis = await checkSkillsReportResult();
    const list = {
      completedSkillsGapAnalysis,
      courseEnrolled,
      uploadedProfilePic,
      watchedLesson,
    };
    setOnboardingTasksProgress({
      status: list,
      loading: false,
    });
  };

  const addClassName = (key:OnboardingStatus) => {
    if (onboardingTasksProgress?.status?.[key]) {
      return styles.completed;
    }
    return '';
  };

  const mainHeader = (
    <div className={styles.heading}>
      <h1>
        Getting you started 🎯
      </h1>
    </div>
  );

  const getHeadingElement = (text:string, className:string, customText = '') => (
    <div className={`${styles.taskHeader} ${className}`}>
      <div className={styles.customIcon} />
      <h2>
        {text}
        {customText && <span>{customText}</span>}
      </h2>
    </div>
  );

  useEffect(() => {
    if (profileInfo?.id) {
      checkOnboardingProgress();
    }
  }, [profileInfo?.id, courseRegistrations]);

  if (onboardingTasksProgress.loading) {
    return null;
  }

  return (
    <div className={styles.learnerOnboardingContainer}>
      <Collapse bordered={false} defaultActiveKey="0">
        <Panel showArrow={false} header={mainHeader} key="0">
          <div className={styles.collapseContainer}>
            <Collapse accordion defaultActiveKey={['1']}>
              <Panel
                key="1"
                showArrow={false}
                header={getHeadingElement('Complete a Soft Skills Gap Analysis',
                  addClassName(OnboardingStatus.COMPLETED_SKILLS_ANALYSIS), 'NEW')}
              >
                <li className={addClassName(OnboardingStatus.COMPLETED_SKILLS_ANALYSIS)}>
                  <div className={styles.info}>
                    <h3>Find out where your gaps are in soft skills</h3>
                    {onboardingTasksProgress?.status?.completedSkillsGapAnalysis
                      ? (
                        <Link
                          id="learner-onboarding-view-now"
                          to={`/learner/analysis/${reportId}/summary/`}
                        >
                          View Now
                        </Link>
                      )
                      : (
                        <Link
                          id="learner-onboarding-start-now"
                          to={`/learner/analysis/${reportId}/`}
                          state={{ internalPageNavigation: true }}
                        >
                          Start Now
                        </Link>
                      )}
                  </div>
                </li>
              </Panel>
              <Panel
                key="2"
                showArrow={false}
                header={getHeadingElement('Pick a course', addClassName(OnboardingStatus.COURSE_ENROLLED))}
              >
                <li className={addClassName(OnboardingStatus.COURSE_ENROLLED)}>
                  <div className={styles.info}>
                    <h3>Enroll in your first course and start upskilling!</h3>
                    <Link id="learner-onboarding-browse-courses" to="/learner/browse/">Browse courses</Link>
                  </div>
                </li>
              </Panel>
              <Panel
                key="3"
                showArrow={false}
                header={getHeadingElement('Watch first lesson', addClassName(OnboardingStatus.WATCHED_LESSON))}
              >
                <li className={addClassName(OnboardingStatus.WATCHED_LESSON)}>
                  <div className={styles.info}>
                    <h3>The most important thing is getting started.</h3>
                    {courseRegistrations?.length > 0
                      && !onboardingTasksProgress?.status?.watchedLesson
                       && (
                       <Link
                         id="learner-onboarding-watch-now"
                         to={`/learner/course-registration/${courseRegistrations[0].id}/content/`}
                       >
                         Watch now
                       </Link>
                       )}
                  </div>
                </li>
              </Panel>
              <Panel
                key="4"
                showArrow={false}
                header={getHeadingElement('Upload a profile photo', addClassName(OnboardingStatus.UPLOADED_PROFILE_PIC))}
              >
                <li className={addClassName(OnboardingStatus.UPLOADED_PROFILE_PIC)}>
                  <div className={styles.info}>
                    <h3>Upload a profile photo to complete your account setup!</h3>
                    {!onboardingTasksProgress?.status?.uploadedProfilePic
                       && (
                       <Link id="learner-onboarding-upload-image" to="/learner/account/">
                         Upload Image
                       </Link>
                       )}
                  </div>
                </li>
              </Panel>
            </Collapse>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default LearnerOnboarding;
