import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import {
  TaskType, Link as ImageLink, CourseRegistrationActivity, Task,
} from '../../models';
import * as styles from '../../styles/learner/ContinueWatching.module.scss';
import { fetchLastUpdatedCourseRegistrationActivityForUser } from '../DataStore/CourseRegistrationActivityService';
import { getPathToNavigate, PageKind } from './Services/LearnerNavigations';
import LearnerContext from '../../context/Learner/LearnerContext';
import { CourseRegistrationWithCourse } from '../../types/commons';

type LastUpdatedTask = Task & {
  regId: string,
  image?: ImageLink,
} | null;

/**
 *
 *
 * @return {*}
 */
const ContinueWatching: React.FC = () => {
  const {
    courseRegistrations,
    profileInfo,
    setShowCourseSwitcher,
  } = useContext(LearnerContext);

  const [lastUpdatedTask, setLastUpdatedTask] = useState<LastUpdatedTask>(null);

  /**
   *
   * course registrations are present in context,
   * using the same to find the current reg by ID
   * @param {*} id
   * @return {*}
   */
  const findCourseRegistrationById = (id: string): CourseRegistrationWithCourse => {
    const courseRegistration = courseRegistrations.filter((reg) => reg.id === id)[0];
    return courseRegistration;
  };

  /**
   * finds the task for current registration by id
   * and returns the same
   *
   * @param {*} courseRegistration
   * @param {*} taskId
   */
  const getTaskDetails = (registration: CourseRegistrationWithCourse, taskId: string) => {
    if (registration) {
      const { course } = registration;
      if (course) {
        for (let i = 0; i < course.modules?.length; i += 1) {
          const module = course.modules[i];
          for (let j = 0; j < module?.tasks.length; j += 1) {
            const task = module?.tasks[j];
            if (taskId) {
              if (task?.id === taskId) {
                return task;
              }
            }
          }
        }
      }
    }
    return null;
  };

  /**
   *
   *
   */
  const getLastUpdatedTaskDetailsFromActivity = (activity: CourseRegistrationActivity) => {
    const courseRegId = activity.courseRegistrationID;
    const taskId = activity.taskID;

    const courseRegistration = findCourseRegistrationById(courseRegId);
    const taskDetails = getTaskDetails(courseRegistration, taskId);
    if (taskDetails) {
      setLastUpdatedTask({
        ...taskDetails,
        regId: courseRegistration.id,
        image: courseRegistration?.course?.courseTileImage,
      });
    }
  };

  /**
   *
   *
   */
  const fetchLastUpdateTask = async () => {
    const activity = await
    fetchLastUpdatedCourseRegistrationActivityForUser(profileInfo.id);
    if (activity) {
      getLastUpdatedTaskDetailsFromActivity(activity);
    } else {
      setLastUpdatedTask(null);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      fetchLastUpdateTask();
    }
  }, [profileInfo, courseRegistrations]);

  return (
    lastUpdatedTask
      ? (
        <div className={styles.continueWatchingSection}>
          <div className={styles.sectionTitle}>
            <div className={styles.wrap}>
              <i className="icon-inbox" />
              <p>Your Next Task is</p>
            </div>
            <button
              id="learner-continue-watching-view-all"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setShowCourseSwitcher(true);
              }}
              className={styles.viewAllCta}
            >
              View all
            </button>
          </div>
          <div className={styles.recordingWrapper}>
            <div className={styles.leftContent}>
              <img
                src={lastUpdatedTask?.image?.href}
                alt="continue-watching"
              />
              <div className={styles.recordingDetails}>
                <p className={styles.title}>
                  {lastUpdatedTask.taskType === TaskType.VIDEO
                    ? lastUpdatedTask.videoTask?.lessonName : ''}
                  {lastUpdatedTask.taskType === TaskType.ASSIGNMENT
                    ? lastUpdatedTask.assignmentTask?.title : ''}
                  {lastUpdatedTask.taskType === TaskType.FINAL_ASSIGNMENT
                    ? lastUpdatedTask.finalAssignmentTask?.title : ''}
                </p>
                {/* <p className={styles.info}>Module 1  •  Lesson 1</p> */}
              </div>
            </div>
            <Link
              id="learner-continue-watching-navigate-to-next-task"
              to={getPathToNavigate(PageKind.COURSE_REGISTRATION, {
                regId: lastUpdatedTask.regId,
              })}
              className={styles.playIconWrapper}
            >
              <i className="icon-new-Play" />
            </Link>
          </div>
        </div>
      ) : <></>
  );
};

export default ContinueWatching;
