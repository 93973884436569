// extracted by mini-css-extract-plugin
export var enrolledCoursesSection = "EnrolledCourses-module--enrolledCoursesSection--3x38i";
export var headerWrapper = "EnrolledCourses-module--headerWrapper--2F2dh";
export var heading = "EnrolledCourses-module--heading--1x76_";
export var carouselControls = "EnrolledCourses-module--carouselControls--1JwXm";
export var prevCtaWrapper = "EnrolledCourses-module--prevCtaWrapper--2jNiW";
export var prevCta = "EnrolledCourses-module--prevCta--3ha11";
export var ellipseWrapper = "EnrolledCourses-module--ellipseWrapper--2Myb0";
export var ellipse = "EnrolledCourses-module--ellipse--35Y1c";
export var nextCtaWrapper = "EnrolledCourses-module--nextCtaWrapper--29s6X";
export var nextCta = "EnrolledCourses-module--nextCta--2BMgL";
export var enrolledCourseList = "EnrolledCourses-module--enrolledCourseList--2Mgxw";
export var enrolledCoursesWrapper = "EnrolledCourses-module--enrolledCoursesWrapper--kJ8bS";
export var dummyScrollBarWrapper = "EnrolledCourses-module--dummyScrollBarWrapper--vM8EE";
export var dummyScrollBar = "EnrolledCourses-module--dummyScrollBar--1ozgX";
export var courseCardWrapper = "EnrolledCourses-module--courseCardWrapper--3ob6O";
export var viewMoreCourseCta = "EnrolledCourses-module--viewMoreCourseCta--2Edbq";
export var addCourse = "EnrolledCourses-module--addCourse--2TCQi";
export var hide = "EnrolledCourses-module--hide--1lAUh";