import React, { useContext } from 'react';
import { Spin } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from '../../styles/learner/Home.module.scss';
// import Notifications from './Notifications';
import LearnerContext from '../../context/Learner/LearnerContext';
import EnrolledCourses from './EnrolledCourses';
import ContinueWatching from './ContinueWatching';
import NoName from './NoName';
import FreeTrialBanner from './FreeTrialBanner';
import LearnerOnboarding from './LearnerOnboarding';
import { EnterpriseSubscriptionStatus } from '../../models';

/**
 *
 *
 * @return {*}
 */
const Home: React.FC = () => {
  const {
    profileInfo, courseRegistrations, loading, enterpriseInfo,
  } = useContext(LearnerContext);

  const nonRenewingStatus = enterpriseInfo
    ?.subscription?.status === EnterpriseSubscriptionStatus.NON_RENEWING;

  return (
    <>
      {(loading || !profileInfo?.id) && (
      <div className={styles.loading}>
        <Spin size="large" />
      </div>
      )}
      {!loading && profileInfo?.id
      && (
      <div className={`${styles.home} library-shaw-icon-new`}>
        {!profileInfo?.name && <NoName />}
        {!!profileInfo?.name && (
        <>
          <div className={styles.logoSection}>
            <StaticImage
              src="../../../static/images/learner/brand-icon.png"
              alt="Upskillist"
              height={40}
              width={40}
            />
            {enterpriseInfo?.logo
              ? (
                <>
                  <div className={styles.division} />
                  <div className={styles.enterpriseLogo}>
                    <img src={enterpriseInfo?.logo} alt={enterpriseInfo?.name} />
                  </div>
                </>
              ) : null}
          </div>
          <div className={styles.headingSection}>
            <p className={styles.headline}>
              Welcome,
              {' '}
              <span>{profileInfo?.name}</span>
            </p>
            {/* <Notifications /> */}
          </div>
          <div className={styles.content}>
            <div className={`${courseRegistrations?.length > 0 ? '' : styles.adjustWidth} ${
              styles.registrationListingSection}`}
            >
              <div className={styles.enrolledSection}>
                {
                nonRenewingStatus
                && (
                <div className={`${styles.freeTrialSection} ${styles.showOnMobile}`}>
                  <FreeTrialBanner />
                </div>
                )
                }
                <EnrolledCourses />
                <ContinueWatching />
              </div>
              <div className={styles.rightSectionContent}>
                {
                nonRenewingStatus
                && (
                  <div className={`${styles.freeTrialSection} ${styles.hideOnMobile}`}>
                    <FreeTrialBanner />
                  </div>
                )
                }
                <LearnerOnboarding />
              </div>
            </div>
            <div className={styles.notificationSection} />
          </div>
        </>
        )}
      </div>
      )}
    </>
  );
};

export default Home;
