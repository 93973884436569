import React, { FC, useContext } from 'react';
import { Link } from 'gatsby';
import { getDateDifference, isEligibleToManageBilling } from '../../services/utils';
import * as styles from '../../styles/learner/FreeTrialBanner.module.scss';
import LearnerContext from '../../context/Learner/LearnerContext';
import { NavigationRoute } from '../Admin/Services/NavigationService';

// lessons progress
// const completed = 2;
// const elements = 10;
// const totalLessons = 10;
// const parts = totalLessons / 10;

// trial progress

// const createChunk = (arr:number[], size:number) => arr.reduce(
//   (acc:any, e:any, i:number) => (
//     i % size
//       ? acc[acc.length - 1].push(e)
//       // eslint-disable-next-line no-sequences
//       : acc.push([e]), acc), [],
// );

// const chunk = createChunk(Array.from({ length: totalLessons }, (_, i) => i + 1), parts);

// const indexOfTargetElement = chunk.findIndex((item:any) => (item.includes(completed)));

// const handleSvgStyle = (index:number) => {
//   const lastIndex = 9; // svg elements = 10
//   if (index < lastIndex) {
//     if (index <= indexOfTargetElement) {
//       return true;
//     }
//   } else if (index === lastIndex) {
//     return completed === totalLessons;
//   }
//   return false;
// };

/**
 *
 *
 * @return {*}
 */
const FreeTrialBanner: FC = () => {
  const { enterpriseInfo, profileInfo } = useContext(LearnerContext);

  const remainingDays = getDateDifference(enterpriseInfo?.subscription?.currentTermEnd, new Date());
  const totalDays = getDateDifference(
    enterpriseInfo?.subscription?.currentTermEnd,
    enterpriseInfo?.subscription?.currentTermStart,
  );
  const value = (remainingDays / totalDays) * 100;

  const showBillingLink = isEligibleToManageBilling(
    enterpriseInfo?.subscription, profileInfo?.roles,
  );

  return (
    <div className={styles.bannerContainer}>
      {/* <div className={styles.leftSection}>
      <div>
        <svg viewBox="0 0 100 100">
          {Array.from({ length: elements }, (_, index) => (
            <circle
              key={index}
              cx="50"
              cy="50"
              r="45"
              className={handleSvgStyle(index) ? styles.completed : ''}
            />
          ))}
        </svg>
        <p>08</p>
      </div>
      <h5>Lessons available</h5>
    </div> */}
      {/* <div className={styles.divider} /> */}
      <div className={styles.rightSection}>
        <h2>
          {remainingDays}
          <span>{`/${totalDays} Days`}</span>
        </h2>
        <div className={styles.progressSection}>
          <div className={styles.progress} />
          <div
            className={styles.pointer}
            style={{
              left: `${value >= 100 ? '' : `${value}%`}`,
            }}
          />
        </div>
        <div className={styles.moreContent}>
          <p>Days Left</p>
          {
            showBillingLink
            && (
              <Link to={NavigationRoute.BILLING_PAGE}>
                <span className={styles.billingCTA}>Upgrade</span>
              </Link>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default FreeTrialBanner;
