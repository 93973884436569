// extracted by mini-css-extract-plugin
export var home = "Home-module--home--I4iHc";
export var logoSection = "Home-module--logoSection--2ek4H";
export var division = "Home-module--division--dadH6";
export var enterpriseLogo = "Home-module--enterpriseLogo--1sAd5";
export var headingSection = "Home-module--headingSection--3K3dh";
export var headline = "Home-module--headline--w6p-t";
export var content = "Home-module--content--3ixXQ";
export var heading = "Home-module--heading--2a6Ns";
export var registrationListingSection = "Home-module--registrationListingSection--2eblw";
export var adjustWidth = "Home-module--adjustWidth--21ptS";
export var enrolledSection = "Home-module--enrolledSection--1Kxmm";
export var rightSectionContent = "Home-module--rightSectionContent--3pEQ3";
export var freeTrialSection = "Home-module--freeTrialSection--2iaeW";
export var freeTrialText = "Home-module--freeTrialText--QY076";
export var notificationSection = "Home-module--notificationSection--3YusU";
export var showOnMobile = "Home-module--showOnMobile--3LkBt";
export var hideOnMobile = "Home-module--hideOnMobile--3v882";
export var loading = "Home-module--loading--sam3n";