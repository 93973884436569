import React, { useEffect } from 'react';
import Layout from '../../components/Learner/Layout';
import Authenticate from '../../components/Identity/Authenticate';
import Home from '../../components/Learner/Home';
import { viewedPage } from '../../services/TrackActionService';
import { PageKind } from '../../components/Learner/Services/LearnerNavigations';
import { LearnerProvider } from '../../context/Learner/LearnerContext';

/**
 *
 *
 * @return {*}
 */
const Learner: React.FC = () => {
  useEffect(() => {
    viewedPage({
      pageKind: PageKind.HOME,
    });
  }, []);

  return (
    <LearnerProvider>
      <Layout>
        <Home />
      </Layout>
    </LearnerProvider>
  );
};

export default Authenticate(Learner);
