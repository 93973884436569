import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import { Progress } from 'antd';
import * as styles from '../../styles/learner/EnrolledCourseCard.module.scss';
import { fetchCourseRegistrationActvitiesByRegistrationId } from '../DataStore/CourseRegistrationActivityService';
import {
  AssignmentResponse,
  Course, CourseRegistration, CourseRegistrationActivity,
  CourseRegistrationActivityStatus, TaskType,
} from '../../models';
import { fetchAssignmentResponseForCourseByTaskId } from '../DataStore/AssignmentResponseService';

type Registration = CourseRegistration & {
  course: Course,
};

type Props = {
  registration: Registration,
}

type CourseDetailsType = {
  totalModules: number,
  completedTasks: number,
}
/**
 *
 *
 * @param {*} {course}
 * @return {*}
 */
const EnrolledCourseCard: React.FC<Props> = ({ registration }) => {
  const [courseDetails, setCourseDetails] = useState<CourseDetailsType>({
    totalModules: 4,
    completedTasks: 0,
  });

  const [
    assignmentResponse,
    setAssignmentResponse] = useState<AssignmentResponse[]>([] as AssignmentResponse[]);
  /**
   *
   * fetches the course registration activities for the current
   * registration and sets the registration progress
   */
  const computeRegistrationProgress = async () => {
    const totalModules = registration?.course?.modules.length;
    const activities: CourseRegistrationActivity[] = await
    fetchCourseRegistrationActvitiesByRegistrationId(registration.id);

    if (activities?.length) {
      const eligibleTaskTypes = [TaskType.FINAL_ASSIGNMENT, TaskType.ASSIGNMENT, TaskType.VIDEO];

      const completedTasks = activities.filter(
        (activity) => eligibleTaskTypes.includes(activity?.taskType as TaskType)
      && activity.status === CourseRegistrationActivityStatus.COMPLETED,
      ).length;

      const finalAssignments = activities.filter(
        (activity) => activity?.taskType === TaskType.FINAL_ASSIGNMENT,
      );

      setCourseDetails({
        totalModules,
        completedTasks,
      });

      if (finalAssignments.length > 0) {
        checkAssignmentCompletion(finalAssignments);
      }
    }
  };

  const checkAssignmentCompletion = async (finalAssignments: CourseRegistrationActivity[]) => {
    const regId = registration?.id;
    const promises = finalAssignments.map((assignment) => (
      fetchAssignmentResponseForCourseByTaskId(regId, assignment?.taskID)
    ));
    const finalAssignmentResponse = await Promise.all(promises);
    const assignmentData:any = [];
    finalAssignmentResponse.forEach((response:AssignmentResponse) => {
      if (response) {
        assignmentData.push(response);
      }
    });
    setAssignmentResponse(assignmentData);
  };

  const calculateAveragePercentage = () => {
    let sum = 0;
    assignmentResponse.forEach((response) => {
      if (response?.totalScore) {
        sum += Math.ceil((response?.score
          / response?.totalScore) * 100);
      }
    });
    return Math.floor(sum / courseDetails.totalModules);
  };

  const diplomaScore = useMemo(calculateAveragePercentage, [assignmentResponse]);

  useEffect(() => {
    if (registration?.id) {
      computeRegistrationProgress();
    }
  }, [registration]);

  return (
    <div
      className={styles.enrolledCourseItem}
    >
      <Link to={`/learner/course-registration/${registration?.id}/content/`} className={styles.courseImage}>
        <img
          src={registration?.course?.courseHeroBannerImage?.href}
          alt={registration?.course?.courseHeroBannerImage?.title}
        />
      </Link>
      <p className={styles.courseName}>
        {registration?.course?.courseName}
        <span className={styles.courseNameTooltip}>
          {registration?.course?.courseName}
        </span>
      </p>
      <div className={styles.task}>
        <div className={styles.courseInfoWrapper}>
          {
            assignmentResponse.length !== courseDetails.totalModules
          && (
            <div className={styles.taskCompletionInfo}>
              <p className={styles.taskTitle}>Tasks</p>
              {
                  courseDetails.completedTasks
                    ? (
                      <p className={styles.tasksCompleted}>
                        {courseDetails?.completedTasks}
                        {' '}
                        completed
                      </p>
                    )
                    : (
                      <p className={styles.tasksCompleted}>
                        Not yet started
                      </p>
                    )
            }
            </div>
          )
          }
          {
            (assignmentResponse.length !== 0)
          && (
          <div className={styles.scoreContainer}>
            <div className={styles.scoreWrapper}>
              <p className={styles.scoreTitle}>Diploma score</p>
              <div className={styles.scoreInfo}>
                <Progress type="circle" showInfo={false} percent={diplomaScore} strokeColor="#a6ede2" strokeWidth={15} width={15} />
                <p className={styles.scorePercentage}>
                  {diplomaScore}
                  %
                </p>
              </div>
            </div>
            {
              (assignmentResponse?.length !== courseDetails.totalModules)
               && <p className={styles.scoreNote}>{`Score displayed only for ${assignmentResponse?.length}/${courseDetails.totalModules} modules`}</p>
            }
          </div>
          )
          }
        </div>
      </div>
    </div>
  );
};

export default EnrolledCourseCard;
