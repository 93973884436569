import React, { useContext, useState } from 'react';
import LearnerContext from '../../context/Learner/LearnerContext';
import * as styles from '../../styles/learner/NoName.module.scss';
import { getCurrentUser, updateName } from '../Shared/Services/AuthService';

/**
 *
 *
 * @return {*}
 */
const NoName: React.FC = () => {
  const { setLoading, profileInfo, setProfileInfo } = useContext(LearnerContext);
  const [name, setName] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
  };
  const submitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const user = await getCurrentUser();
    await updateName(user, {
      name,
      sub: user.attributes.sub,
      id: profileInfo?.id,
    });
    setProfileInfo({
      ...profileInfo,
      name,
    });
    setLoading(false);
  };
  return (
    <div className={styles.noName}>
      <form onSubmit={submitForm}>
        <h3>We’ll use your name on your certificate</h3>
        <label htmlFor="name">
          Full Name
          <input
            type="text"
            id="name"
            name="name"
            placeholder="John Smit"
            onChange={handleChange}
          />
        </label>
        <button type="submit" disabled={!name}>Continue</button>
      </form>
    </div>
  );
};

export default NoName;
